import axios from "axios";
import { BACKEND_URL } from "../constants";
import { toast } from "react-toastify";

class AuthService {
  get(new_id: string) {
    return axios.get(BACKEND_URL + "node/" + new_id + "/6/6");
  }
  delete(id: string) {
    return axios.delete(BACKEND_URL + "edit/" + id, this.getConfig());
  }
  create(
    id: string,
    name: string,
    type: string,
    content: string,
    parent: string
  ) {
    return axios.post(
      BACKEND_URL + "edit/" + id,
      {
        id: id,
        name: name,
        type: type,
        content: content,
        parent: parent,
      },
      this.getConfig()
    );
  }
  change_name(id: string, new_name: string) {
    return axios.patch(
      BACKEND_URL + "edit/" + id,
      {
        Name: new_name,
      },
      this.getConfig()
    );
  }
  change_order(id: string, new_order: number) {
    return axios.patch(
      BACKEND_URL + "edit/" + id,
      {
        Order: new_order.toString(),
      },
      this.getConfig()
    );
  }
  change_type(id: string, new_type: string) {
    return axios.patch(
      BACKEND_URL + "edit/" + id,
      {
        Type: new_type,
      },
      this.getConfig()
    );
  }
  change_content(id: string, new_content: string) {
    return axios
      .patch(
        BACKEND_URL + "edit/" + id,
        {
          Content: new_content,
        },
        this.getConfig()
      )
      .catch((error) => {});
  }

  log_in(
    username: string,
    password: string,
    setLoggedIn: (_: boolean) => void
  ) {
    return axios
      .post(
        BACKEND_URL + "token/",
        {
          username: username,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          //withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.access) {
          let resdata = response.data;
          resdata["name"] = username;
          localStorage.setItem("user", JSON.stringify(resdata));
          setLoggedIn(true);
          toast("Logged in");
        } else {
          setLoggedIn(false);
          toast("Login failed");
        }
      })
      .catch((error) => {
        setLoggedIn(false);
        toast("Login failed");
      });
  }

  logout(setLoggedIn: (_: boolean) => void) {
    localStorage.removeItem("user");
    setLoggedIn(false);
    toast("Logged out");
  }

  getConfig() {
    let bearer = "";
    let authed = authService.getCurrentUser();
    if (authed !== null) {
      if (authed.access) {
        bearer = authed.access;
      }
    }
    const config = {
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    };

    return config;
  }

  getCurrentUser() {
    const userStr = localStorage.getItem("user");
    if (userStr) return JSON.parse(userStr);
    return null;
  }
}

const authService = new AuthService();

export default authService;
