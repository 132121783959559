import { Input, InputGroup, InputGroupText } from "reactstrap";
import { TreeNode } from "../Types";
import backendService from "../../services/backend.service";
import { SetStateAction } from "react";

const BoolNode = (
  node: TreeNode,
  load_node: (new_id: string) => void,
  handleTempContentChange: (event: {
    target: { value: SetStateAction<string> };
  }) => void,
  temporaryContent: SetStateAction<string>,
  isLoggedIn: SetStateAction<boolean>
) => {
  return (
    <InputGroup key={Math.random().toString() + node.ID}>
      <InputGroupText key={Math.random().toString() + node.ID}>
        <Input
          addon
          type="checkbox"
          key={Math.random().toString() + node.ID}
          id={"checkbox-878a7-" + node.ID}
          checked={node.Content === "true"}
          onChange={() => {}}
          onClick={() => {
            backendService
              .change_content(node.ID, "" + !(node.Content === "true"))
              .then((res) => {
                load_node(node.ID);
              });
          }}
        />
      </InputGroupText>
      <InputGroupText key={Math.random().toString() + node.ID}>
        {node.Name}
      </InputGroupText>
    </InputGroup>
  );
};
export default BoolNode;
