import { Card, CardBody, CardHeader } from "reactstrap";
import { TreeNode } from "../Types";
import ReactMarkdown from "react-markdown";
import { SetStateAction } from "react";
import Bool from "./Bool";
import Integer from "./Integer";
import RangedInteger from "./RangedInteger";

const ValuesNode = (
  node: TreeNode,
  load_node: (new_id: string) => void,
  handleTempContentChange: (event: {
    target: { value: SetStateAction<string> };
  }) => void,
  temporaryContent: SetStateAction<string>,
  isLoggedIn: SetStateAction<boolean>
) => {
  return (
      <Card key={Math.random().toString() + node.ID}>
        <CardHeader key={Math.random().toString() + node.ID}>
          {node.Name}
        </CardHeader>
        <CardBody key={Math.random().toString() + node.ID}>
          <ReactMarkdown key={Math.random().toString() + node.ID}>
            {temporaryContent.toString()}
          </ReactMarkdown>
          {node.Children.filter(
            (node: TreeNode) =>
              node.Type === "Bool" ||
              node.Type === "Integer" ||
              node.Type === "RangedInteger" ||
              node.Type === "Values"
          ).map((child: TreeNode) =>
            child.Type === "Values" ? (
              ValuesNode(
                child,
                (new_id) => load_node(node.ID),
                handleTempContentChange,
                child.Content,
                isLoggedIn
              )
            ) : child.Type === "Bool" ? (
              Bool(
                child,
                (new_id) => load_node(node.ID),
                handleTempContentChange,
                child.Content,
                isLoggedIn
              )
            ) : child.Type === "Integer" ? (
              Integer(
                child,
                (new_id) => load_node(node.ID),
                handleTempContentChange,
                child.Content,
                isLoggedIn
              )
            ) : child.Type === "RangedInteger" ? (
              RangedInteger(
                child,
                (new_id) => load_node(node.ID),
                handleTempContentChange,
                child.Content,
                isLoggedIn
              )
            ) : (
              <></>
            )
          )}
        </CardBody>
      </Card>
  );
};
export default ValuesNode;
