import { Input, InputGroup, InputGroupText } from "reactstrap";
import { TreeNode } from "../Types";
import backendService from "../../services/backend.service";
import { SetStateAction } from "react";

const RangedInteger = (
  node: TreeNode,
  load_node: (new_id: string) => void,
  handleTempContentChange: (event: {
    target: { value: SetStateAction<string> };
  }) => void,
  temporaryContent: SetStateAction<string>,
  isLoggedIn: SetStateAction<boolean>
) => {

  let min = 0;
  let max = 10;

  node.Children.filter(
    (child: TreeNode) => child.Name === "min"
  ).forEach((child: TreeNode) =>
    {min = parseInt(child.Content);}
  )

  node.Children.filter(
    (child: TreeNode) => child.Name === "max"
  ).forEach((child: TreeNode) =>
    {max = parseInt(child.Content);}
  )

  return (
    <InputGroup key={Math.random().toString() + node.ID}>
      <InputGroupText key={Math.random().toString() + node.ID}>
        {node.Name}: {node.Content} / {max}
      </InputGroupText>
      {isLoggedIn ? (
        <InputGroupText key={Math.random().toString() + node.ID}>
          <Input
            key={Math.random().toString() + node.ID}
            name="range"
            type="range"
            min={min}
            max={max}
            step="1"
            addon
            defaultValue={node.Content}
            onMouseUp={(e) => {
              if (e.currentTarget.value !== node.Content) {
                backendService
                  .change_content(node.ID, "" + e.currentTarget.value)
                  .then((res) => {
                    load_node(node.ID);
                  });
              }
            }}
          />
        </InputGroupText>
      ) : (
        <></>
      )}
    </InputGroup>
  );
};
export default RangedInteger;
