import { TreeNode } from "../../Types";
import ReactMarkdown from "react-markdown";
import { CardDeck, Col, Row } from "reactstrap";
import Markdown from "../../genericNodeTypes/Markdown";
import { SetStateAction } from "react";
import Values from "../../genericNodeTypes/Values";

const SRCharacterNode = (
  node: TreeNode,
  load_node: (new_id: string) => void,
  handleTempContentChange: (event: {
    target: { value: SetStateAction<string> };
  }) => void,
  temporaryContent: SetStateAction<string>,
  isLoggedIn: SetStateAction<boolean>
) => {
  return (
    <>
      <Row>
        {node.Children.filter(
          (child: TreeNode) => child.Name === "Portrait"
        ).map((child: TreeNode) => (
          <Col xs="auto" key={"j3gdhs" + child.ID}>
            {Markdown(child, load_node, handleTempContentChange, child.Content, isLoggedIn)}
          </Col>
        ))}
        <Col>
          <ReactMarkdown>{temporaryContent.toString()}</ReactMarkdown>
        </Col>
        <Row>
          <CardDeck
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            {node.Children.filter(
              (child: TreeNode) => child.Type === "Values"
            ).map((child: TreeNode) =>
              Values(
                child,
                (new_id) => load_node(node.ID),
                handleTempContentChange,
                child.Content,
                isLoggedIn
              )
            )}
          </CardDeck>
        </Row>
      </Row>
    </>
  );
};
export default SRCharacterNode;
