import { Button, Card, CardBody, CardDeck, Col, Row } from "reactstrap";
import { TreeNode } from "../../Types";
import ReactMarkdown from "react-markdown";
import SRCharacterNode from "./SRCharacter";
import { SetStateAction } from "react";

const SRCharactersNode = (
  node: TreeNode,
  load_node: (new_id: string) => void,
  handleTempContentChange: (event: {
    target: { value: SetStateAction<string> };
  }) => void,
  temporaryContent: SetStateAction<string>,
  isLoggedIn: SetStateAction<boolean>
) => {
  return (
    <Col>
      <Row>
        <ReactMarkdown key={"2fxx112e3" + node.ID}>
          {temporaryContent.toString()}
        </ReactMarkdown>
      </Row>
      <Row>
        <CardDeck
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "5px",
          }}
        >
          {node.Children.filter(
            (child: TreeNode) => child.Type === "SR Character"
          ).map((child: TreeNode) => (
            <Card
              key={"gzu7fzf7" + child.ID}
              style={{
                width: "24rem",
              }}
            >
              <CardBody>
                <Button
                  onClick={() => {
                    load_node(child.ID);
                  }}
                  outline
                  key={"kaej3" + child.ID}
                  style={{
                    textAlign: "left",
                  }}
                >
                  {child.Name}
                </Button>
                {SRCharacterNode(
                  child,
                  (new_id) => load_node(node.ID),
                  handleTempContentChange,
                  child.Content,
                  isLoggedIn
                )}
              </CardBody>
            </Card>
          ))}
        </CardDeck>
      </Row>
    </Col>
  );
};
export default SRCharactersNode;
